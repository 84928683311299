<template>
  <div class="labsList">
    <span id="loading" v-if="this.loading == true">
      <Loading />
    </span>
    <h2>Laboratórios</h2>
    <br />
    <div class="searchbar">
      <b-form-input
        class="float-right"
        align-v="center"
        v-model="filter"
        type="search"
        placeholder="Procurar Laboratório"
        label-cols-sm="3"
        label-align-sm="right"
        @input="pageOne()"
      >
        ></b-form-input
      >
    </div>
    <b-pagination
      v-model="currentPage"
      pills
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="my-table"
      align="center"
    ></b-pagination>
    <div class="table">
      <b-table
        striped
        hover
        :items="labs"
        :fields="fields"
        :filter="filter"
        :per-page="perPage"
        :current-page="currentPage"
        thead-class="text-white"
        small
      >
        <template #cell(actions)="data">
          <b-button class="btnActions" size="sm" variant="warning" @click="editLab(data.item.id)"
            >Editar</b-button
          >
          <b-button class="btnActions" size="sm" variant="danger" @click="deleteLab(data.item.id)"
            >Excluir</b-button
          >
        </template>
      </b-table>
    </div>
    <b-pagination
      class="customPagination"
      v-model="currentPage"
      pills
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="my-table"
      align="center"
    ></b-pagination>
    <b-modal
      id="modal-edit-lab"
      ref="modal-edit-lab"
      title="Editar Laboratório"
      hide-footer
    >
    <form v-on:submit.prevent="add"></form>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Loading from "@/components/Loading.vue";
export default {
  components: {
    Loading,
  },
  name: "Labs",
  data() {
    return {
      loading: true,
      perPage: 100,
      currentPage: 1,
      filter: null,
      rows: 1,
      labs: [],
      fields: [
        {
          key: "id",
          label: "ID",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle",
          sortable: true,
        },
        {
          key: "name",
          label: "Labs",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle",
          sortable: true,
        },
        {
          key: "email",
          label: "Email",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle",
          sortable: true,
        },
        {
          key: "days_to_resend",
          label: "Dias após envio",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle",
          //   sortable: true,
        },
        {
          key: "email_support",
          label: "Email de suporte",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle",
        },
        {
          key: "actions",
          label: "Ações",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle",
        },
      ],
    };
  },
  methods: {
    async getAllLabs() {
      this.loading = true;
      try {
        await this.$store.dispatch("allLaboratories");
        this.labs = this.getAllLaboratories;
        /* VERIFICA QUANTAS LINHAS TERÁ A TABELA */
        this.rows = this.labs.length;
      } catch (err) {
        alert(err);
      } finally {
        this.loading = false;
      }
    },
    pageOne() {
      this.currentPage = 1;
    },
  },
  computed: {
    ...mapGetters(["getAllLaboratories"]),
  },
  async created() {
    await this.getAllLabs();
  },
};
</script>

<style scoped>
.searchbar {
  position: absolute;
  right: 0px;
  width: 200px;
  margin: 10px 20px 0px 0px;
}

h2 {
  margin-top: 20px;
}

.table {
  font-size: 12px;
  margin-top: 30px;
}

.labsList {
  text-align: center;
}

.btnActions{
    margin: 0px 5px;
}
</style>
